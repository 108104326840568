import axios from 'axios'
import hapusStoreMixin from '@/helper/hapusLocalStore'

export const retribusiApi = {
    mixins: [hapusStoreMixin],
    data () {
        return {
            detailRetJenis1Mixin: [],
            detailRetJenis2Mixin: [],
            detailRetJenis3Mixin: [],
            detailRetTarifMixin: [],
        }
    },
    methods: {
        detailRetJenis1 (id) {
            axios.get("/api/retribusi/ret-jenis-1/" + id).then(response => {
                const items = response.data.data
                this.detailRetJenis1Mixin = items
                this.form.fill(this.detailRetJenis1Mixin)
                this.$store.dispatch("RefRek/getRefRek6", this.form.ref_rek_5_id)
                this.$store.dispatch("Perangkat/getUnit", this.form.perangkat_pd_id)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus token mixin
                    this.clearAll()
                }
            })
        },
        detailRetJenis2 (id) {
            axios.get("/api/retribusi/ret-jenis-2/" + id).then(response => {
                const items = response.data.data
                this.detailRetJenis2Mixin = items
                this.form.fill(this.detailRetJenis2Mixin)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus token mixin
                    this.clearAll()
                }
            })
        },
        detailRetJenis3 (id) {
            axios.get("/api/retribusi/ret-jenis-3/" + id).then(response => {
                const items = response.data.data
                this.detailRetJenis3Mixin = items
                this.form.fill(this.detailRetJenis3Mixin)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus token mixin
                    this.clearAll()
                }
            })
        },
        detailRetTarif (id) {
            axios.get("/api/retribusi/ret-tarif/" + id).then(response => {
                const items = response.data.data
                this.detailRetTarifMixin = items
                this.form.fill(this.detailRetTarifMixin)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus token mixin
                    this.clearAll()
                }
            })
        }
    }
}

export default retribusiApi