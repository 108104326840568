<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel title="DAFTAR TARIF RETRIBUSI" class="panel panel-success">

            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2" v-role:any="'Administrator|Maintenance'">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-0"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="inputPerangkat(perangkatpd)"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->
                        
                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width:
                                field.key === 'aktif' ? '65px' : 
                                field.key === 'actions' ? '105px' : ''
                        }"
                    />
                </template>
                <template #head()="data">
                    <div class="text-center" v-html="data.label"></div>
                </template>
                <template #cell(ret_jenis_1)="data">
                    <div v-if="data.item.ret_jenis_3.ret_jenis_2.ret_jenis_1">
                        {{ data.item.ret_jenis_3.ret_jenis_2.ret_jenis_1.nama }}
                    </div>
                </template>
                <template #cell(ret_jenis_2)="data">
                    <div v-if="data.item.ret_jenis_3.ret_jenis_2">
                        {{ data.item.ret_jenis_3.ret_jenis_2.nama }}
                    </div>
                </template>
                <template #cell(ret_jenis_3)="data">
                    <div v-if="data.item.ret_jenis_3">
                        {{ data.item.ret_jenis_3.nama }}
                    </div>
                </template>
                <template #cell(parameter)="data">
                    <div v-html="data.item.parameter_1"></div>
                    <div v-html="data.item.parameter_2"></div>
                    <div v-html="data.item.parameter_3"></div>
                    <div v-html="data.item.parameter_4"></div>
                </template>
                <template #cell(tarif)="data">
                    {{ data.item.tarif.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}
                </template>
                <template #cell(aktif)="data">
                    <div v-if="data.item.aktif" class="label label-success">Aktif</div>
                    <div v-else class="label label-danger">Non Aktif</div>
                </template>
                <!-- BAGIAN ACTIONS -->
                <!-- <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        size="sm"
                        class="d-flex align-item-center"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="view(data.item.id)"
                            ><i class="fa fa-eye"></i>
                            Lihat</b-dropdown-item
                        >
                    </b-dropdown>
                </template> -->
                <!-- end BAGIAN ACTIONS -->
            </b-table>
            <div class="divider"></div>

            <!-- BAGIAN PAGINATION -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group
                        :label-cols="2"
                        label="Per page"
                        class="mb-0"
                    >
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end BAGIAN PAGINATION -->
        </panel>
        <!-- Panel end here -->
        
        <!--- modal view -->
        <b-modal
            title="Detail Klasifikasi"
            v-model="showModalView"
            id="modal-view"
            hide-footer
            no-close-on-backdrop
        >
            <form>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Jenis Retribusi 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="Retribusi.ret_jenis_3"
                            :reduce="nama => nama.id"
                            v-model="form.ret_jenis_3_id"
                            placeholder="-Pilih-"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('kode_dh')
                            }"
                            v-model="form.kode_dh"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 1
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_2"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 2
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_2"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_3"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 4
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_4"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('tarif')
                                    }"
                                    v-model="form.tarif"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif Satuan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'tarif_st'
                                        )
                                    }"
                                    v-model="form.tarif_st"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Status Aktif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="aktifOptions"
                                    :reduce="nama => nama.id"
                                    v-model="form.aktif"
                                    placeholder="-Pilih-"
                                    disabled
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";
import dataRetribusi from "@/helper/api/retribusi_jenis.js";

export default {
    mixins: [hapusToken, dataRetribusi],
    components: {
        HeadTitle
    },
    data() {
        return {
            selected: "1",
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            role: JSON.parse(localStorage.getItem("roles"))[0],

            /**
             * BAGIAN FILTER
             * --------------------------------------------------
             */
            perangkatpd: "all",

            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "ret_jenis_1",
                    label: "JENIS PELAYANAN",
                    sortable: true
                },
                {
                    key: "ret_jenis_2",
                    label: "RINCIAN",
                    sortable: true
                },
                {
                    key: "ret_jenis_3",
                    label: "SUB RINCIAN",
                    sortable: true
                },
                {
                    key: "parameter",
                    label: "PARAMETER",
                    sortable: true
                },
                {
                    key: "tarif",
                    label: "TARIF",
                    sortable: true,
                    tdClass: 'text-right'
                },
                {
                    key: "kode_dh",
                    label: "KODE",
                    sortable: true
                },
                {
                    key: "aktif",
                    label: "STATUS",
                    sortable: true
                },
                // {
                //     key: "actions",
                //     label: "Opsi",
                //     sortable: false
                // }
            ],
            status_id: null,
            editMode: false,
            showModalForm: false,
            showModalView: false,
            form: new Form({
                ret_jenis_3_id: "",
                kode_dh: "",
                parameter_1: "",
                parameter_2: "",
                parameter_3: "",
                parameter_4: "",
                tarif: "",
                tarit_st: "",
                aktif: ""
            }),
            errors: [],
            isLoading: false,
            editorConfig: {
                removeButtons:
                    "Image,Table,Cut,Copy,RemoveFormat,Anchor,Source,Styles,Format,About,Blockquote"
            },
            aktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktif"
                },
                {
                    id: 1,
                    nama: "Aktif"
                }
            ]
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState(["PemerintahDaerah", "filterTest", "RefRek", "Perangkat", "Retribusi"])
    },
    mounted() {
        this.$store.dispatch("Retribusi/getRetJenis3");
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/retribusi/ret-tarif", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    perangkatpd: this.perangkatpd,
                    retjenis1: true,
                    retjenis2: true,
                    retjenis3: true,
                    rettarif: true
                }
            });
            return promise
                .then(response => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/retribusi/ret-tarif/" + id)
                        .then(response => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000
                            }).catch(error => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        view(id) {
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal-view");
            this.showModalView == true;
            // detailRetribusi mixin
            this.detailRetTarif(id);
        },
        close() {
            this.form.reset();
            this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm == false;
        },

        /**
         * BAGIAN FILTER
         * --------------------------------------------------
         */
        inputPerangkat(value) {
            this.perangkatpd = value;
            this.reload();
        },
    }
};
</script>

<style scoped>

</style>
