<template>
    <div class="card mb-3">
        <div class="card-body logo-position">
            <div class="row">
                <div class="col-sm-1">
                    <img :src="imageLogo" alt="logo" width="50">
                </div>
                <div class="col-sm-8 ">
                    <div class="font-weight-bold" style="font-size: 13px">PEMERINTAH PROVINSI SUMATERA UTARA</div>
                    <div class="font-weight-bold" style="font-size: 11px">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</div>
                    <div class="font-weight-bold" style="font-size: 9px">BIDANG RETRIBUSI DAN PENDAPATAN LAINNYA</div>
                    <div style="font-size: 10px" class="font-weight-bold text-muted"><i class="fas fa-map-marker-alt"></i> Jl. Sisingamangaraja Km 5,5 Medan</div>
                </div>
                <div class="col-sm-3 hide-label">
                    <span class="float-right px-3 border-left"><img :src="logoPBBKB" alt="logo" width="150"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LogoSumut from '@/assets/images/logoSumut.png'
import logoPBBKB from '@/assets/images/logo.png'

export default {
    data () {
        return {
            imageLogo: LogoSumut,
            logoPBBKB: logoPBBKB
        }
    }
}
</script>

<style scoped>
.border-left {
    border-style: dashed !important;
    border-right-style: hidden !important;
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
}
@media only screen and (max-width: 600px) {
    .logo-position {
        text-align: center;
    }
    .hide-label {
        display: none;
    }
}
</style>